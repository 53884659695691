const csMessages = {
  cs: {
    reservationForm: {
      errorMessages: {
        dokladOUskladneni:
          'Zadaná hodnota nesplňuje požadovaný formát. Číslo uskladnění je ve formátu "USKL-00-00000".',
      },
    },
    contactForm: {
      agreementError: 'Pro odeslání formuláře musíte odsouhlasit podmínky!',
    },
    registrationForm: {
      errorMessages: {
        passwordIsWeak: [
          'Slabé heslo.',
          ' Heslo musí mít nejméně 8 znaků, kde je alespoň jedno velké písmeno,',
          ' malé písmeno a číslo a nesmí obsahovat mezeru.',
        ],
        notSamePassword: 'Potvrzení hesla se neshoduje.',
      },
    },
    shoppingCart: {
      actions: {
        buy: 'Koupit',
      },
      messages: {
        cartManipulations: {
          itemAdded: 'Do košíku byla přidaná položka',
          invalidCoupon: 'Slevový kupón je neplatný',
          invalidProductQuantity: 'Zadané množství produktu není skladem',
          invalidProduct: 'Tento produkt není možné přidat do košíku',
        },
      },
    },
    reactMuiTables: { labelRowsPerPage: 'Počet řádku na stránce' },
    com: {
      globalSearchField: 'Vyhledat',
      phase: {
        label: 'label',
      },
      product: {
        ean: {
          label: 'EAN',
          helperText: '',
          helperTextPreview: '',
        },
        sku: {
          label: 'SKU',
          helperText: '',
          helperTextPreview: '',
        },
        manufacturer: {
          label: 'Manufacturer',
          helperText: '',
          helperTextPreview: '',
        },
        pn: {
          label: 'Part Number',
          helperText: '',
          helperTextPreview: '',
        },
      },
      totalAmount: {
        label: 'Total',
        helperText: '',
      },
    },
    appProductParameterList: {
      season: 'Období',
      width: 'Šířka',
      profile: 'Profil',
      diameter: 'Průměr',
      speedIndex: 'Index rychlosti',
      loadIndex: 'Index nosnosti',
      type: 'Typ',
      warranty: 'Záruka',
    },
    imageGallery: {
      thumbnailsMore: '+ {number} more',
    },

    SxFieldsPriceRangeSliderField: {
      title: 'Cenové rozpětí',
    },

    // PRENIEST DO ORDERS: START
    orders: {
      actions: {
        all: 'All Orders',
      },
      myOrdersScoringBlog: {
        title: 'My Orders',
      },
      ordersList: 'Orders',
      listPage: {
        table: {
          columns: {
            number: 'Číslo faktury',
            order: 'Číslo objednávky',
            total: 'Celková cena',
            contact: 'Kontakt',
            dateOfOrder: 'Datum objednávky',
            dateOfCreate: 'Datum vytvoření objednávky',
            state: 'Stav',
            owner: 'PDF',
          },
        },
      },
    },
    // PRENIEST DO ORDERS: END
    page404: {
      title: 'Bohužel, stránka neexistuje...',
      subTitle: 'Pokračujte prosím na hlavní stránku.',
      button: 'Zpět na hlavní stránku',
    },

    // PRENIEST DO BASKETS: START
    baskets: {
      actions: {
        all: 'All Baskets',
      },
      myBasketsScoringBlog: {
        title: 'Saved Baskets',
      },
      cartSummaryParameters: {
        caption: 'Kod produktu:',
      },
      appCartPayment: {
        title: 'Způsob platby',
      },
      shippingPersonalVariants: {
        title: ' ks skladem',
      },
      appCartShipping: {
        title: 'Způsob dopravy',
        reservationTitle: 'Výběr pobočky pro rezervaci',
        label: 'Vyberte pobočku',
        checkbox: 'Pneumatiky rovnou přezujeme',
        dateLabel: 'Vyberte termín přezutí',
      },
      vatFields: {
        basic: 'Základ',
        vat: 'DPH',
        rate: 'Sazba',
      },
      cartSummaryTotal: {
        tax: 'Daň',
        shipping: 'Doprava',
        payment: 'Platba',
        priceWithout: 'Cena celkem bez DPH',
        priceWith: 'Cena celkem s DPH',
        totalWeight: 'Celková hmotnost',
      },
      cartInquirySwitcher: {
        label: 'Odeslat jako rezervaci',
        popupTitle: 'Poptávka',
        popupText: `Ak si nie ste istý výberom komponentov a chcete poradiť, prípadne máte špeciálne cenové a
        množstevné požiadavky, môžte košík odoslať ako nezáväznú ponuku a budeme sa jej individuálne venovať.
        Cenovú ponuku môžte neskôr opätovne vložiť do košíka a odoslať ako objednávku.`,
      },
      cartSinglePage: {
        title: 'Košík',
        buyButton: 'Odeslat objednávku',
        reservationButton: 'Odeslat rezervaci',
        emptyBasket: 'Váš košík je prázdný',
        buyThere: 'Už jsem zde nakupoval/a',
        login: 'chci se přihlásit',
        notFill: 'Nejsou vyplněny doručovací údaje',
        fill: 'Vyplňte údaje',
        change: 'Změnit údaje',
        requireItems: 'Povinné položky',
        popupButton: 'Zobrazit na mapě',
      },
      cartThankyouPage: {
        title: 'DĚKUJEME',
        subTitle: 'Vaše objednávka číslo {count} byla úspěšně odeslána',
        content: 'o dalším stavu objednávky Vás budeme informovat e-mailem',
        button: 'Zpět do obchodu',
      },
      cartNotes: {
        shippingField: {
          label: 'Poznámka dopravci',
          buttonLabel: 'Přidat poznámku dopravci',
        },
        sellerField: {
          label: 'Poznámka prodejci',
          buttonLabel: 'Přidat poznámku prodejci',
        },
        order: {
          label: 'Vaše číslo objednávky',
        },
        news: 'Pravidelně mě informujte o novinkách',
        marketing: 'Nezasílat marketingové materiály',
        agree1: 'Souhlasím s',
        agree2: 'obchodními podmínkami',
      },
      cartExpansion: {
        button: 'Zavřít',
      },
      cartSummaryDiscountField: {
        button: 'Slevový kupon',
        applyButton: 'Vložit',
      },
      cartSummarySubjectAddManual: {
        title: 'Ruční zadání položky',
        label: 'Název produktu nebo katalogové číslo',
        button: 'Přidat',
        popup: 'Vybrané položky',
      },
      cartClearBasketButton: {
        button: 'Vyprázdnit košík',
        tooltip: 'Opravdu chcete vyprázdnit košík?',
      },
      cartSnackbar: {
        alert: 'Položka přidána do košíku!',
      },
      cartExcessiveIcon: 'Nadrozměrné zboží',
      cartSummary: {
        total: 'Cena položek',
      },
      cartActionBar: {
        alternatives: 'Alternativy',
        sets: 'Sety',
        parts: 'Dily setu',
        required: 'Povinné zboží',
        button: 'Technické údaje',
      },
    },
    // PRENIEST DO BASKETS: END
    productAvailability: {
      format: {
        available: 'Skladem',
        moreThanX: 'Více jak {min} položek skladem',
        exactCount: 'Skladem {value} ks',
        forOrder: 'Na objednání',
        notAvailable: 'Zboží není skladem',
      },
    },
    productRating: {
      button: 'Vložte vaše hodnocení',
      comment: 'Komentář',
      name: 'Jméno',
      send: 'Odeslat',
      title: 'Hodnocení',
    },
    productCard: {
      tags: {
        new: 'Novinka',
        sale: 'Sleva',
        cashback: 'Cashback',
      },
    },
    globalNavigation: {
      home: 'Úvodní stránka',
      shopping: 'Seznam produktů',
      orders: 'Objednávky',
      shoppingLists: 'Shopping Lists',
      configurator: 'Konfigurátor disků',
      ourTireServicesPage: 'Naše prodejny',
    },
    branchServiceItem: {
      openHours: 'Otevírací doba',
      contactInfo: 'Kontaktní údaje',
      shopOffers: 'Nabídka prodejny',
    },
    startPage: {
      title: 'Start Page',
      gotoShopping: 'Go Shopping',
    },
    configuratorPage: {
      title: 'Konfigurátor disků',
      searchButton: 'Zobrazit výsledky',
    },
    productListPage: {
      title: 'Letní pneumatiky osobní',
      filter: {
        search: 'Aplikovat',
        clear: 'Vymazat',
        clearDesktop: 'Vymazat všechny filtry',
        mobileTitle: 'Filtrovat podle',
      },
      sectionDescription: {
        toExpand: 'Celý popis',
        toCollapse: 'Skrátit',
      },
      orders: {
        typo: 'Řadit podle:',
        recommended: 'Doporučeného',
        name: 'Názvu',
        priceAsc: 'Nejlevnějšího',
        priceDesc: 'Nejdražšího',
        catalogueNumber: 'Kat. číslo',
        warehouse: 'Skladem',
      },
    },
    orderStatuses: {
      storno: 'Storno',
      synchronized: 'Sended',
      waiting: 'Waiting for synchronization',
    },
    orderPreview: {
      items: 'items',
    },
    cartRegisterForm: {
      title: 'Register Form',
    },
    cartPayment: {
      title: 'Payment',
    },
    cartPreSummaryPage: {
      title: 'Cart Summary',
      actions: {
        nextStep: 'Agree and Continue',
      },
    },
    cartSummaryDiscountCode: {
      title: 'Slevový kupón',
      label: 'Zadejte prosím číslo kupónu',
    },
    productAvailabilityFieldPreviewWithPopup: {
      button: 'When can i get item?',
    },
    productGifts: '+ gifts',
    article: {
      commentCount: ['{count, plural,', 'one {1 comment}', 'other {{count} comments}', '}'],
    },
    appProductListItemCard: {
      compare: {
        title: 'Porovnat',
      },
    },
    ourTireServicesPage: {
      pageTitle: 'Naše prodejny',
    },
    homepage: {
      logo: 'Prodej-pneu.cz',
      bestSellerProducts: 'Nejprodávanější produkty',
      bestSellerSizes: 'Nejprodávanější rozměry',
      posts: 'Příspěvky',
      hotTips: 'Akce',
      productOfMonth: 'Top of Month',
      catalogFavorite: 'Favourite Categories',
      recommended: 'Doporučujeme',
      productSets: 'Sets',
      slider: {
        text1: 'Nová kování pro ',
        text2: 'skleněná zábradlí',
      },
      productsTabs: {
        recommended: 'Doporučujeme',
        top: 'Akční',
        sale: 'Výprodej',
      },
    },
    productContactForm: {
      firstName: 'Jméno',
      lastName: 'Příjmení',
      subject: 'Předmět',
      question: 'Dotaz',
      gdpr: 'Odesláním dotazu beru na vědomí zpracování osobních údajů, za účelem odpovědi na dotaz.',
      gdprLink: 'GDPR link',
      button: 'Odeslat dotaz',
      subjectContent: 'Dotaz k produktu: {product}, kód: {sku}',
    },
    lastVisitedProducts: 'Last visited products',
    productAlternativeProducts: 'Alternativy',
    productAccessories: 'Doplnek',
    productReplacements: 'Nahrada',
    appProductDetailTabs: {
      description: 'Popis',
      parameters: 'Parametry',
      package: 'Obsah balení',
      query: 'Dotaz',
      alternatives: 'Alternativy',
      form: {
        enum: '!!!! ENUM VALUE',
      },
    },
    productDetailStockInfo: {
      stockInfo: 'Skladová dostupnost',
    },
    productDetailSelectVariant: {
      availabilities: 'Výberte provozovnu',
    },
    productParametersTires: { bannerLabel: 'Ekologický štítek' },
    cartShippingBanner: {
      text: 'Pro vložení nadrozměrného produktu bude vaše cena za dopravu vyšší',
    },
    cartFormCompany: {
      button: {
        text: 'Dohledat v ARES dle IČO',
      },
      checkboxLabel: 'Nakupuji na firmu',
      companyIDLabel: 'IČO',
      companyNameLabel: 'Firma',
      vatLabel: 'DIČ',
    },
    cartFormShipping: {
      checkbox: {
        title1: 'Zaslat zboží na jinou adresu',
        title2: '',
      },
      companyNameLabel: 'Firma',
      firstNameLabel: 'Jméno',
      lastNameLabel: 'Příjmení',
      streetLabel: 'Ulice',
      cityLabel: 'Město',
      zipLabel: 'PSČ',
      companyIDLabel: 'IČO',
      countryLabel: 'Stát',
    },
    cartFormPersonal: {
      label: 'Kontaktní údaje',
      firstNameLabel: 'Jméno',
      lastNameLabel: 'Příjmení',
      streetLabel: 'Ulice, číslo popisné',
      phoneLabel: 'Telefon',
      pscLabel: 'PSČ',
      cityLabel: 'Město',
      countryLabel: 'Země',
      emailLabel: 'Email',
      passwordLabel: 'Heslo',
      controlPasswordLabel: 'Kontrolní heslo',
    },
    cartFormInvoice: {
      label: 'Fakturační údaje',
      streetLabel: 'Ulice',
      companyLabel: 'Společnost',
      pscLabel: 'PSČ',
      icoLabel: 'IČO',
      dicLabel: 'DIČ',
      country: 'Země',
      numberOfDescriptiveLabel: 'Číslo',
      cityLabel: 'Město',
      zipLabel: 'PSČ',
      countryLabel: 'Stát',
      button: 'Uložit změny',
      name: 'Jméno a příjmení',
    },
    cartSummaryPage: {
      title: 'Summary before ordering',
      actions: {
        continueShopping: 'Continue Shopping',
        saveOrder: 'Create an Order',
      },
      expectedDeliveryDate: {
        label: 'Requested Delivery Date',
        helperText: '',
        helperTextPreview: '',
      },
      noteForSeller: {
        label: 'Note for Supplyer',
        helperText: '',
        helperTextPreview: '',
      },
      paymentGateway: {
        label: 'Payment Gateway',
        helperText: '',
        helperTextPreview: '',
      },
    },
    userProfile: {
      title: 'Můj profil',
      content: 'Scoring blog',
      myOrders: 'Moje objednávky',
      myInvoices: 'Moje rezervace',
      logout: 'Odhlásit se',
      changePassword: {
        title: 'Změna hesla',
        fields: {
          login: 'Login (nelze měnit)',
          originalPassword: 'Původní heslo',
          newPassword: 'Nové heslo',
          confirmNewPassword: 'Potvrzení hesla',
        },
        button: 'Uložit změny',
      },
      contactInfo: {
        title: 'Kontaktní údaje',
        newsletter: {
          title: 'Newsletter',
          content: 'Chci odebírat newsletter Digiexpert.si',
        },
      },
      orders: {
        title: 'Moje objednávky',
      },
      invoices: {
        title: 'Moje rezervace',
      },
    },

    orderSavedThankPage: {
      title: 'Thank You for Your Order',
      thankMessage: [
        `Check your email shortly for a confirmation of your order.
        Please keep it for your records.
        Down you can find information about your order.`,
      ],
      order: 'Order Number:',
      payment: 'Payment was:',
      decline: 'decline',
      accepted: 'accepted',
      actions: {
        back: 'Back to Home',
        detail: 'Order detail',
        continue: 'Continue shopping',
      },
    },
    shoppingLists: {
      title: 'Shopping Lists',
      actions: {
        saveAsShoppingList: 'Save as Shopping List',
        importShoppingListToCart: 'Import',
      },
    },
    searchResultPage: {
      search: 'Vyhledávaný výraz:',
      results: 'Výsledky vyhledávání',
    },
    homepageFavoriteCategories: {
      title: 'Oblíbené kategorie',
    },
    homePageInfo: {
      text: 'Na pobočce v Oticích probíhá dnes inventura. Otevírací doba jen do 14:00 hodin',
    },
    homePageArticles: {
      title: 'Novinky a návody',
      button: 'Všechny novinky',
    },
    footer: {
      newsletter: {
        label: 'Zadejte váš e-mail',
        text: 'Odebírejte naše novinky a akce!',
        button: 'Přihlásit se k odběru',
      },
    },
    faqPage: {
      title: 'Často kladené otázky',
    },
    termsOfDeliveryPage: {
      title: 'Dodací podmínky',
    },
    termsAndConditionsPage: {
      title: 'Obchodní podmínky',
    },
    loginPage: {
      title: 'Login Page',
      forgotPassword: 'Reset hesla',
      email: 'E-mail',
      sendEmail: 'Send email',
      info: 'Bude Vam zaslany e-mail s odkazem na zmenu hesla',
      password: 'Password',
      notSame: 'is not the same',
      newPassword: 'New password',
      confirmPassword: 'Confirm password',
      changePassword: 'Change Password',
    },
    appBarActions: {
      favoriteItems: 'Oblíbené položky',
      search: 'Vyhledat',
      login: 'Přihlásit se',
    },
    appMainMenu: {
      b2b: 'Jste B2B? Přihlaste se. Výhodnější ceny.',
      login: 'Přihlásit se',
    },
    mainNavigationNarrow: {
      category: 'Kategorie',
      login: 'Přihlásit se',
    },
    appMainMenuInfo: {
      phone: '+420 606 650 272',
      call: 'Volejte',
    },
    appMainMenuLinks: {
      news: 'Novinky',
      tests: 'Testy pneumatik',
      techHelp: 'Technický rádce',
      termsAndConditions: 'Obchodní podmínky',
      contact: 'Kontakt',
    },
    productDetailPage: {
      parameters: {
        diameter: 'Průměr',
        loadIndex: 'Index nosnosti',
        profile: 'Profil',
        season: 'Období',
        speedIndex: 'Index rychlosti',
        width: 'Šířka',
        mark: 'Značka',
      },
      ecoBadge: 'Ekologický štítek',
      ecoBadgeNoiseClass: 'Noise Class',
      code: 'Kód produktu',
      stockInfo: {
        inStock: 'Skladem',
        sendInfo: 'Odešleme již zítra',
      },
      price: 'Cena včetně DPH',
      availability: {
        workingDay: 'Objednejte do 13:30 a odesíláme',
        afterWorkingDay: 'Objednejte dnes a odesíláme',
      },
      registration: {
        text: 'Zaregistrujte se pro lepší cenu!',
      },
      disponibility: {
        primaryLessThan: 'SKLADEM méně než {count} kusů',
        primaryMoreThan: 'SKLADEM {count, plural, zero {jeden kus} other {{count} a více kusů}}',
        secondaryLessThan: 'SKLADEM v přípravném skladu méně než {count} kusů',
        secondaryMoreThan: 'SKLADEM v přípravném skladu {count, plural, zero {jeden kus} other {{count} a více kusů}}',
        unavailable: 'Zboží není dostupné',
        showroom: '(vystaveno v Shoowroomu)',
        soldout: 'Zboží vyprodáno',
        'on-way': {
          d: `{from, plural,
            one {Uz zitra to tu bude!!!}
            two {Na ceste pozitri}
            few {Na ceste za {from} dny}
            other {Na ceste, za {from} dni}
          }'`,
          m: `Na cestě za {from, plural,
            one { minutu}
            two {minuty}
            other {minut}
          }'`,
          M: `Na cestě za {from, plural,
            one { měsíc }
            two { měsíce }
            few { měsíce }
            other { měsíců }
          }'`,
          dRange: `Na ceste za {to, plural,
            few {{from}-{to} dny}
            other {{from}-{to} dni}
          } `,
          mRange: `Na ceste za {from}-{to, plural,
            one {den}
            few {dny}
            other {dní}
          }`,
          MRange: `Na ceste za {from}-{to, plural,
            one { měsíc }
            two { měsíce }
            few { měsíce }
            other { měsíců }
          }`,
          date: '{date}',
        },
        time: 'Na ceste za {count, plural, zero {jeden kus} other {{count} a více kusů}}',
      },
    },
    comparePage: {
      title: 'Compare',
      removeButton: 'Remove product',
      nothing: 'Nothing to be compare',
    },
    filter: {
      reset: 'Reset filtru',
      search: 'Vyhledat',
      filterBtn: 'Filtrovat',
    },
    homepageFilter: { brandButton: 'Filtrovat podle značek', button: 'Zobrazit' },
    orderTermsAndConditions: {
      select: {
        label: 'Order Terms And Conditions',
        helperText: '',
        helperTextPreview: '',
      },
    },

    orderDetailPage: {
      actions: {
        import: 'Import to Cart',
      },
    },
    invoiceBlogPreview: {
      title: 'My invoices',
      button: 'All invoices',
    },
    invoices: {
      actions: {
        addNewInvoice: 'Add new Invoice',
        all: 'All Invoices',
      },
      listPage: {
        title: 'Invoices',
        table: {
          filter: {
            number: 'Number',
          },
          columns: {
            number: 'Číslo rezervace',
            invoiceDate: 'Datum rezervace',
            total: 'Total',
            totalWithTax: 'Total With Tax',
            billingAddress: 'Billing Address',
            orderNumber: 'Název prodejny',
            deliveryNoteNumber: 'Stav rezervace',
          },
        },
      },
    },
    userProfilePage: {
      logout: 'Logout',
      login: 'Přihlásit se',
      setting: 'Setting',
      passwordChange: 'Change password',
    },
    appBarActionRegion: {
      changeRegion: 'Změnit jazyk',
    },
    subCategories: {
      title: 'Podkategorie',
    },
    personalIcon: {
      menu: {
        orders: 'Moje nákupy',
        settings: 'Nastavení',
        logout: 'Odhlásit se',
      },
      dialog: {
        title: 'Přihlášení',
        userName: 'Email',
        password: 'Heslo',
        button: 'Přihlásit se',
        forgottenPwd: 'Zapomenuté heslo',
      },
    },
    // oauthClients: {
    //   menuPrimary: 'Clients',
    //   listPage: {
    //     pageTitle: 'OAuth Clients',
    //   },
    //   newPage: {
    //     pageTitle: 'New Client',
    //   },
    //   clientFields: {
    //     name: {
    //       label: 'Name',
    //       helperText: '',
    //       helperTextPreview: '',
    //     },
    //     clientId: {
    //       label: 'Client Id',
    //       helperText: '',
    //       helperTextPreview: '',
    //     },
    //     clientSecret: {
    //       label: 'Client Secret',
    //       helperText: '',
    //       helperTextPreview: '',
    //     },
    //     isTrusted: {
    //       label: 'Is Trusted',
    //       helperText: '',
    //       helperTextPreview: '',
    //     },
    //     redirectUris: {
    //       label: 'Redirect URIs',
    //       helperText: '',
    //       helperTextPreview: '',
    //     },
    //   },
    //   actions: {
    //     addnew: 'Add New Client',
    //     editClient: 'Edit',
    //     addnewRedirectUri: 'Add Redirect URI',
    //   },
    // },

    // oauthUsers: {
    //   menuPrimary: 'Users',
    // },
    companyPage: {
      title: 'O firmě',
      companyFullName: 'Umakov CZ s.r.o.',
      address: 'nám. Osvobození č. ev. 63  664 82 Říčany Česká republika',
      IR: 'IČ: 01692771',
      VAT: 'DIČ: CZ01692771',
      phone: 'Telefon: +420 608 743 164, +420 774 533 989, +420 608 313 799',
      email: {
        title: 'e-mail: ',
        email: 'umakov@umakov.cz',
      },
      web: {
        title: 'www: ',
        web: 'www.umakov.cz',
      },
      registration: 'Registrace vedená u Krajského soudu v Brně, spisová značka C79090',
      bankAccont: {
        title: 'Bankovní spojení:',
        bankName: 'Fio banka a.s.',
        accountNumber: 'č. ú.: 2000473237/2010',
        IBAN: 'IBAN: CZ08 2010 0000 0020 0047 3237',
      },
    },
    carrierPage: {
      title: 'Kariéra',
      titleContainer: 'Nabízené pracovní pozice:',
      position1: 'Obchodní zástupce pro CZ',
      position2: 'Prodejce, Praha - Otice',
      position3: 'Skladník, Praha - Otice',
    },
    contactPage: {
      title: 'Kontakty',
      contactTitles: {
        position: 'Pozice',
        phone: 'Telefon',
        mobile: 'Mobil',
        email: 'E-mail',
      },
    },
    shopsPage: {
      title: 'Polotovary pro nerezové a skleněné zábradlí',
      address: 'Adresa',
      zip: 'PSČ',
      seller: 'Prodávající',
      phone: 'Telefon',
      fax: 'Fax',
      email: 'E-mail',
      web: 'Internet',
      open: 'Otevřeno',
    },
  },
};

export default csMessages;
