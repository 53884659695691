import type { FormikErrors } from '@sprinx/react-forms';
import formSectionErrorSub from '@sprinx/react-forms/formSectionErrorSub';
import defaultsDeep from 'lodash/defaultsDeep';
import { DeepPartial } from 'utility-types';
import { ApplicationAuthState } from '../../@sprinx/react-after-razzle/stateStore';
import calculateSectionCompletedByFields from './calculateSectionCompletedByFields';
import { CheckoutSectionCommonState } from './types';

export interface SectionState extends CheckoutSectionCommonState {
  data: SectionStateData;
}

export interface SectionStateData {
  companyName?: string;
  companyRegNo?: string;
  companyTaxNo?: string;
  emailAddress: string;
  personName: string;
  personOrCompany: 'person' | 'company';
  phoneNumber: string;
  postAddress: {
    city: string;
    country: string;
    postalCode: string;
    street: string;
  };
}

export function createDefaultState(initialData?: DeepPartial<SectionStateData>): SectionState {
  return {
    completed: false,
    data: createDefaultData(initialData),
  };
}

export function createDefaultData(initialData: DeepPartial<SectionStateData> = {}): SectionStateData {
  return defaultsDeep(
    {
      companyName: undefined,
      companyRegNo: undefined,
      companyTaxNo: undefined,
      emailAddress: undefined,
      personName: undefined,
      phoneNumber: undefined,
      personOrCompany: 'person',
      postAddress: {
        city: undefined,
        country: 'CZ',
        postalCode: undefined,
        street: undefined,
      },
    },
    initialData,
  );
}

export function calculateCompletedByFields(
  value: SectionState | undefined,
  error: FormikErrors<SectionState> | string | undefined,
): boolean {
  const perror = formSectionErrorSub(error);
  const errorData = formSectionErrorSub(perror?.data);
  const errorDataPostAddress = formSectionErrorSub(errorData?.postAddress);

  // prettier-ignore
  return calculateSectionCompletedByFields([
    [value?.data?.personName, errorData?.personName],
    [value?.data?.emailAddress, errorData?.emailAddress],
    [value?.data?.phoneNumber, errorData?.phoneNumber],
    [value?.data?.postAddress?.city, errorDataPostAddress?.city],
    [value?.data?.postAddress?.street, errorDataPostAddress?.street],
    [value?.data?.postAddress?.postalCode, errorDataPostAddress?.postalCode],
    ...(value?.data?.personOrCompany === 'company'
      ? ([
          [value?.data?.companyName, errorData?.companyName],
          [value?.data?.companyRegNo, errorData?.companyRegNo],
          [value?.data?.companyTaxNo, errorData?.companyTaxNo],
        ] as [any, any][])
      : []),
  ]);
}

export function updateBillingInfoSectionFromAuth(auth: ApplicationAuthState<any> | undefined) {
  return <T extends { billingInfoSection: SectionState }>(prev: T): T => {
    if (!auth) return prev;
    if (!auth.isAuthenticated) return prev;
    if (!auth.profile) return prev;

    return {
      ...prev,
      billingInfoSection: {
        ...prev.billingInfoSection,
        data: (auth.profile as any).invoiceInfo || createDefaultData(),
      },
    };
  };
}
