import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import MuiComponentsProvider from '@sprinx/react-mui-components/MuiComponentsProvider';
import MuiFieldsProvider from '@sprinx/react-mui-fields/MuiFieldsProvider';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Helmet } from 'react-helmet';
import { RecoilRoot, useRecoilValue } from 'recoil';
import LayoutProvider from './@sprinx/react-after-razzle/LayoutProvider';
// import { SseListenerConnect } from './@sprinx/react-after-razzle/sseListener';
import { AppInitializationState, initializeAppState } from './@sprinx/react-after-razzle/stateStore';
import './api/appState';
import { currencyState, supportedLocaleCodesState } from './api/appState';
import './api/catalogue/catalogue';
import './api/catalogue/catalogueFilter';
import './api/checkout';
import './api/mainNavigation';
// import './api/products/catalogue';
import './api/products/productParameterGroupTypes';
import './api/products/productParameters';
import './api/products/productParameterTypes';
import './api/products/taxonomyTypes';
import './api/shopping';
import './api/shoppingCart';
import './api/snackbars';
import AppComponentLoader from './AppComponentLoader';
import AppGlobalize from './AppGlobalize';
import ShoppingBootstrap from './components/ShoppingBootstrap';
import ShoppingCartDecisionCartInOtherSession from './components/ShoppingCartDecisionCartInOtherSession';
import ShoppingCartRemoteChangeListener from './components/ShoppingCartRemoteChangeListener';
import SnackbarMessageCatcher from './components/SnackbarMessageCatcher';
import AppLayout from './layouts/AppLayout';
import theme from './theme';

export interface AppProps {
  children: NonNullable<React.ReactNode>;
  initialAppProps: Partial<AppInitializationState<any, any, any, any>>;
}

const App: React.FC<AppProps> = ({ children, initialAppProps }) => {
  return (
    <RecoilRoot initializeState={initializeAppState(initialAppProps)}>
      <Helmet titleTemplate='%s | PRODEJ-PNEU.cz' defaultTitle='PRODEJ-PNEU.cz' />
      <React.Suspense fallback={<AppComponentLoader />}>
        <AppInitialized>{children}</AppInitialized>
      </React.Suspense>
    </RecoilRoot>
  );
};

App.displayName = 'App';

export default App;

const AppInitialized: React.FC = ({ children }) => {
  const supportedLocales = useRecoilValue(supportedLocaleCodesState);
  const currency = useRecoilValue(currencyState);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <SseListenerConnect /> */}
      <SnackbarProvider>
        <SnackbarMessageCatcher />
        <AppGlobalize>
          <MuiComponentsProvider>
            <MuiFieldsProvider currencyDefault={currency} supportedLanguages={supportedLocales}>
              <LayoutProvider defaultLayout={AppLayout}>{children}</LayoutProvider>
              <ShoppingBootstrap />
              <ShoppingCartRemoteChangeListener />
              <ShoppingCartDecisionCartInOtherSession />
            </MuiFieldsProvider>
          </MuiComponentsProvider>
        </AppGlobalize>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
